@use '@carbon/styles/scss/utilities/custom-property' as theme;
@use '@carbon/styles/scss/spacing' as *;
@use '@carbon/colors' as carbonColor;
@import '../base/colors';
@import '../abstracts/variables';
@import '../abstracts/mixins';

.filter-container {
  display: flex;
  align-items: center;
  margin: $spacing-05 0px;
  .cds--dropdown__wrapper {
    width: 250px;
  }
}
.data-grid {
  &-container {
    max-height: 38rem;
    overflow: auto;
  }
  &-actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 $spacing-03;
  }
  &-csv-btn {
    color: transparent;
  }
}

.cross-shop-circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: theme.get-var('focus');
  border: 2px solid theme.get-var('border-subtle');
  float: right;
}

.report-table {
  &wrapper {
    box-shadow: $boxShadow-01;
  }
  &-container {
    margin-top: $spacing-03;
    .data-table-container {
      border: 1px solid theme.get-var('border-subtle');
      border-radius: $border-radius-01;
      background: theme.get-var('background');
      .cds--data-table {
        border-collapse: separate;
        border-spacing: 0;
        thead {
          tr {
            @include body-emphasis-02;

            background: theme.get-var('table-header-main');
            th {
              transition: 110ms;
              transition: border-radius 110ms;
            }
          }
          &.rounded tr {
            th:first-of-type {
              border-top-left-radius: $border-radius-01;
            }
            th:last-of-type {
              border-top-right-radius: $border-radius-01;
            }
          }
        }
      }
    }
  }
  &-edit-name-wrapper {
    display: flex;
    align-items: center;
  }
  &-save-icon {
    margin-left: $spacing-05;
    &--disabled {
      color: theme.get-var('text-disabled');
      cursor: default;
    }
  }
  &-edit-icon {
    margin: $spacing-02 0 0 $spacing-05;
  }
}

tr {
  .report-name-cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .report-name-cell--edit {
      color: transparent;
    }
  }
  &:hover {
    .report-name-cell--edit {
      color: theme.get-var('text-primary');
    }
  }
  .schedule-flag-cell {
    display: none;
  }
  .actions-cell {
    display: flex;
    gap: $spacing-03;
    align-items: center;
    .cds--overflow-menu,
    .cds--overflow-menu svg,
    .cds--popover-container {
      border-radius: 4px;
      width: 18px;
      height: 18px;
    }
    &.disabled {
      color: theme.get-var('text-disabled');
      > span {
        cursor: not-allowed;
      }
      svg.icon-filled {
        fill: theme.get-var('text-disabled');
      }
    }
    svg.icon-filled {
      fill: theme.get-var('background-primary');
    }
  }

  .report-name-container {
    display: flex;
    align-items: center;
    gap: $spacing-04;
  }

  .ReportTableRow {
    &__schedule-cell {
      display: flex;
      span {
        padding: 0 $spacing-02;
        white-space: nowrap;
      }
    }

    &__shared-cell {
      display: flex;
      gap: $spacing-05;
      justify-content: space-between;
      min-width: 100px;
    }
  }
  .toolbar-buttons {
    .cds--btn--primary:not(:first-of-type)::before {
      position: absolute;
      top: 15px;
      left: 0;
      display: block;
      width: 1px;
      height: 1rem;
      background-color: theme.get-var('background');
      content: '';
      opacity: 1;
    }
    .cds--btn--primary:not(:first-of-type):hover::before {
      opacity: 0;
    }
  }
}

.cds--data-table {
  tr.cds--expandable-row[data-child-row]:hover,
  tr.cds--expandable-row[data-child-row]:hover td,
  tr.cds--parent-row.cds--expandable-row:hover + tr[data-child-row] td,
  tr.cds--parent-row.cds--data-table--selected.cds--expandable-row
    + tr[data-child-row]
    td {
    background: theme.get-var('background');
    background-color: theme.get-var('background');

    tr:hover td {
      background-color: theme.get-var('background-hover');
    }
  }
  tr.cds--parent-row.cds--expandable-row + tr[data-child-row] td {
    padding: $spacing-05 0 $spacing-05 $spacing-12;
    background-color: theme.get-var('layer-selected-01');
  }
}

.expanded-row-container {
  padding: $spacing-05 0;
  display: flex;
  flex-direction: column;
  gap: $spacing-05;

  .scheduled-reports-container {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: $spacing-03;
    .report-list {
      max-height: 250px;
      overflow-y: auto;

      .report-row {
        display: flex;
        height: 40px;
        border-bottom: 1px solid theme.get-var('border-subtle');

        > div {
          padding: $spacing-03;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25%;

          &:nth-child(1) {
            width: 45%;
            justify-content: flex-start;
            padding-left: 0;
            span {
              text-decoration: underline;
              color: theme.get-var('background-primary');
              cursor: pointer;
            }
          }
          &:nth-child(4) {
            width: 34px;
            padding: 0;
            .cds--btn {
              padding: $spacing-03;
              min-height: 16px;
            }
          }
          &.status-cell {
            width: fit-content;
            .cds--tag {
              padding: $spacing-02 $spacing-05;
            }
          }
        }
      }
    }
  }
}

.websocket-status {
  display: flex;
  align-items: center;
  &.indeterminate {
    &::after {
      display: inline-block;
      animation: $animated-ellipsis;
      content: '';
    }
  }
  svg {
    &.icon-success {
      fill: theme.get-var('text-success');
    }
    &.icon-info {
      fill: theme.get-var('text-info');
    }
    &.icon-warning {
      fill: theme.get-var('text-warning');
    }
    &.icon-error {
      fill: theme.get-var('text-error');
    }
  }
  .cds--inline-notification {
    min-height: 1.5rem;
    .cds--inline-notification__details {
      align-items: center;
      .cds--inline-notification__icon {
        height: $spacing-04;
        margin: 0;
      }
      .cds--inline-notification__text-wrapper {
        padding: 0;
      }
    }
  }
}

.workspace-container {
  margin-top: $spacing-05;
  .cds--tabs {
    .workspace-tab {
      @include body-02;
      padding: $spacing-04 $spacing-05;
      max-width: fit-content;
      > div {
        display: flex;
        align-items: center;
        gap: $spacing-03;
        svg {
          color: theme.get-var('icon-neutral');
        }
      }
      &.cds--tabs__nav-item--selected > div {
        @include body-emphasis-02;
        span {
          color: theme.get-var('text-primary');
        }
        svg {
          color: theme.get-var('icon-primary');
        }
      }
    }
  }

  .tabs-list-container {
    margin-bottom: 28px;
  }

  .tab-panel-container {
    padding: 24px;
    border-radius: $border-radius-01;
    background-color: theme.get-var('background');
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
    margin-bottom: $spacing-07;
  }

  .cds--pagination {
    border: 1px solid theme.get-var('border-subtle');
    border-radius: 0 0 $border-radius-01 $border-radius-01;
  }

  .filters-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: $spacing-03;

    .report-type-filter-container {
      width: fit-content;
      min-width: 30%;
    }

    .report-name-filter-input-container {
      width: 360px;
    }

    .date-filter-container {
      display: flex;
      gap: 10px;
      > div {
        position: relative;
        .flatpickr-calendar {
          z-index: 3;
        }
      }
      .cds--form-item {
        max-width: 144px;

        .cds--date-picker.cds--date-picker--single .cds--date-picker__input {
          width: 100%;
        }
      }
      .cds--date-picker__input {
        @include body-03;
      }
    }

    > div {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-grow: 1;
      &:last-of-type {
        max-width: fit-content;
      }
      @media (max-width: 1024px) {
        flex-wrap: wrap;
      }
    }

    .filters-button {
      width: 100px;
    }

    .cds--popover-container .cds--popover {
      z-index: 3;
    }
  }
}

.cds--overflow-menu-options.cds--overflow-menu-options--open {
  border-radius: $border-radius-01;
}

.cds--overflow-menu-options.cds--overflow-menu-options--open.table-row-overflow-menu {
  padding: $spacing-05;
  min-width: 260px;
  border-radius: $border-radius-01;
  z-index: 1;
  &[data-floating-menu-direction]::after {
    width: 0;
  }
  .cds--overflow-menu-options {
    &__option {
      border-bottom: 1px solid theme.get-var('border-subtle');
    }
    &__btn {
      color: theme.get-var('text-primary');
      max-width: 100%;
      align-items: center;
      gap: $spacing-05;
      svg {
        fill: theme.get-var('icon-primary');
      }
    }
  }
}

.report-table-wrapper.hidden-table {
  .cds--data-table-container,
  .cds--pagination {
    display: none;
  }
}

.table-status-wrapper {
  display: flex;
  align-items: center;
}

.table-status {
  @include body-02;
  white-space: nowrap;
  height: 18px;
  &.Completed {
    background-color: theme.get-var('background-success');
    color: theme.get-var('text-success');
    @include body-02;
  }
  &.Processing,
  &.Pending_deletion {
    background-color: theme.get-var('background-active');
    color: theme.get-var('text-button-secondary');
    @include body-02;
  }
  &.Completed_with_no_data,
  &.Failed,
  &.Cancelled {
    background-color: theme.get-var('background-error');
    color: theme.get-var('text-error');
    @include body-02;
  }
  &.Processing {
    width: 75px;
    display: block;
    padding-right: 1.25rem;
    &:after {
      display: inline-block;
      animation: $animated-ellipsis;
      content: '';
    }
  }
  &.Scheduled {
    background-color: theme.get-var('background-warning');
    color: #b28600;
    @include body-02;
  }
}

.cds--toolbar-content.DataGrid__table-toolbar {
  gap: 10px;
  width: auto;
  flex-grow: 1;
  align-items: center;
  z-index: 3;
  .DataGrid__grid-switching {
    position: relative;
    flex-grow: 1;
    .DataGrid__grid-switching-button {
      padding-right: $spacing-08;
    }
    .cds--content-switcher-btn,
    .cds--content-switcher__label {
      overflow: unset;
    }
    .cds--tooltip-content {
      max-width: 360px;
      white-space: initial;
    }
    .DataGrid__grid-switcher-button-error {
      &::before {
        content: none;
      }
      .DataGrid__grid-switching-error {
        display: flex;
        align-items: center;
        gap: $spacing-03;
        color: theme.get-var('text-error');
        .cds--popover-container {
          height: 18px;
        }
      }
    }
  }
  .visual-dropdowns {
    padding: 0;
    margin: 0;
    height: 32px;
    gap: $spacing-04;
    .cds--dropdown__wrapper {
      margin: 0;
      height: 32px;
      .cds--dropdown {
        height: 32px;
      }
    }
  }
  .cds--inline-loading .cds--inline-loading__text {
    @include body-02;
  }
}

.DataGrid__tooltip-container {
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 5;
  .DataGrid__tooltip-pointer {
    background: theme.get-var('background-inverse');
    height: 8px;
    width: 8px;
    position: absolute;
    top: -4px;
    transform: rotate(45deg);
  }
  .DataGrid__tooltip-label {
    background: theme.get-var('background-inverse');
    color: theme.get-var('text-on-color');
    padding: $spacing-05;
    font-weight: $font-weight-04;
  }
}

.DataGrid__action-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .DataGrid__action-cell--value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: none;
    flex-shrink: 1;
  }

  .DataGrid__action-cell--action-container {
    padding-left: $spacing-05;
    border-left: 1px solid theme.get-var('border-subtle');
    .DataGrid__action-cell--action-button {
      padding: $spacing-02;
      border-radius: $border-radius-01;
      background: transparent;
      align-self: flex-end;
      color: inherit;
      &:hover {
        background-color: theme.get-var('main-button-secondary-hover');
      }
    }
  }
}

.ArchivedReportsTable__deletion-with-icon {
  display: flex;
  justify-content: center;

  svg {
    fill: carbonColor.$red-70;
    margin-left: $spacing-02;
  }
}
.ArchivedReportsTable__deletion {
  display: flex;
  justify-content: center;
}

.cds--toast-notification.DataGrid__notification-toast {
  position: fixed;
  left: 6rem;
  bottom: 1rem;
  animation: fade-in 0.5s;
  z-index: 4;
  width: 22rem;
  border: 1px solid theme.get-var('notification-info-border');
  border-radius: $border-radius-01;
  .cds--toast-notification__caption {
    @include body-03;
  }
  .cds--toast-notification__icon {
    visibility: hidden;
    margin-right: 13px;
  }
  .DataGrid__notification-loading {
    position: absolute;
    top: 10px;
    left: 14px;
  }
  &.failed {
    border-right: none;
    border-bottom: none;
    border-top: none;
    .cds--toast-notification__icon {
      visibility: visible;
    }
    .DataGrid__notification-loading {
      display: none;
    }
  }
}
.DataGrid__download-wrapper {
  padding: $spacing-04;
  .DataGrid__download-btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}

.DataGrid__table-row--clickable {
  cursor: pointer;
}

.DataGrid__dropdowns-container {
  padding: 0 $spacing-03 $spacing-03;
  .visual-dropdowns {
    padding: 0;
    margin: 0;
    gap: $spacing-04;
    .cds--dropdown__wrapper {
      margin: 0;
    }
  }
}
